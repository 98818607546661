<template>
    <div class="formattedTime">
        <span v-if="showHours">{{hours}}<span class="unit">h</span></span>
        <span v-if="showMinutes">{{minutes}}<span class="unit">m</span></span>
        <span>{{seconds}}<span class="unit">s</span></span>
        <span class="millis">{{millis}}</span>
    </div>
</template>

<script>
const pad = function(value, minDigits) {
    const padded = "0" + value;
    const digits = Math.max(minDigits, padded.length - 1);
    return padded.slice(-digits);
}

export default {
    props: ['time'],
    computed: {
        hours: function() {
            return Math.floor(this.time / 3600000);
        },
        minutes: function() {
            return Math.floor((this.time % 3600000) / 60000);
        },
        seconds: function() {
            return Math.floor((this.time % 60000) / 1000);
        },
        millis: function() {
            const ms = this.time % 1000;
            return pad(Math.floor(ms / 10), 2);
        },
        showHours: function() {
            return this.hours > 0;
        },
        showMinutes: function() {
            return this.minutes > 0 || this.showHours;
        }
    }
}
</script>
import Clock from "./clock.js"
import Lap from "./lap.js"

export default class Stopwatch {
    constructor(data) {
        this.id = data.id;
        this.clock = new Clock(data.clock);
        this.laps = data.laps.map(lap => new Lap(lap));
        this.name = data.name;
        this.placeholderName = "Tidtagarur " + (this.id + 1);
    }
    nameOrPlaceholder() {
        return this.name.length > 0 ? this.name : this.placeholderName;
    }
    currentLap() {
        return this.laps[this.laps.length - 1];
    }
    totalAt(time) {
        return this.clock.totalAt(time);
    }
    total() {
        return this.clock.total();
    }
    isResumed() {
        return this.clock.isResumed();
    }
    isStarted() {
        return this.clock.isStarted();
    }
}

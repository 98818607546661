<template>
    <div id="topBox">
        <div id="contentBox">
            <div id="stopwatchView">
                <div id="header">
                    <img id="logo" src="../../../assets/images/logo_beta_text.svg"/>
                    <div id="controlButtons">
                        <button @click="toggleInfo" :class="buttonInfoClass" title="Information"></button>
                        <button @click="removeAll" class="buttonRemoveAll" title="Ta bort alla tidtagarur"></button>
                        <button @click="createNewStopwatch" class="buttonAdd" title="Nytt tidtagarur"></button>
                    </div>
                </div>
                <stopwatch-list
                    class="stopwatchList"
                    :stopwatchList="sorted"/>
            </div>
        </div>
        <div id="overlay" :class="overlayClass" @click="toggleInfo"></div>
        <div id="sidePanel" :class="[sidePanelClass]">
            <div id="info" :class="infoClass" ref="info">
                <div class="panelHeader">
                    <h2>Information</h2>
                    <button @click="toggleInfo" class="buttonCloseInfo" title="Stäng information"></button>
                </div>
                <p>Tidtagare.se är ett tidtagarur online med många lättanvända funktioner.</p>
                <p>Starta och stanna tiden med Start/Stopp-knappen, lägg till varv med Varv-knappen och nollställ ett stoppat ur med Nollställ-knappen.</p>
                <p>Du kan ha flera stoppur igång samtidigt, tryck på den blå plusknappen uppe till höger för att lägga till ett nytt. Tryck på krysset på tidtagaruret för att ta bort det, eller ta bort alla genom att klicka på krysset högst upp på sidan.</p>
                <p>Varje tidtagarur kan namnges för att lättare hålla koll på dem, klicka på dess namn och skriv in ett nytt för att ändra.</p>
                <p>Håll koll på alla varv- och mellantider i varvlistan, och se snabbast, snitt och långsammast varvtid under listan. Varven kan namnges, och varvlistan kan sorteras efter namn, varvtid och mellantid. Tryck på respektive rubrik för att välja sorteringsordning.</p>
                <h2 class="panelTitle">Feedback</h2>
                <p>Den här nya versionen av tidtagare.se är under uppbyggnad och fortfarande i betastadie. Det betyder att du som användare har ett extra bra tillfälle att påverka sajtens funktioner, men också att det kan finnas brister som inte lösts än.</p>
                <p>Saknar du någon funktion, har du förslag på förbättringar eller har du hittat ett fel? Hjälp till att förbättra tidtagare.se genom att skicka feedback till mig, via ett mail till <a href="mailto:jonas@tidtagare.se">jonas@tidtagare.se</a>.</p>
                <h2 class="panelTitle">Integritetspolicy</h2>
                <p>
                    Tidtagare.se bryr sig om din integritet och minimerar mängden data som samlas in om dig.
                    Personlig data du väljer att skicka, exempelvis via mail, sparas bara så länge det är nödvändigt.
                </p>
                <p>
                    Tidtagare.se använder Google Analytics för att spåra och analysera besök på hemsidan.
                    Det görs för att bättre förstå hur hemsidan används, så att den kan förbättras.
                    Ingen personlig information (såsom namn, mailadress etc.) samlas in och ingen analys görs på individnivå.
                    Däremot kan teknisk data såsom mobil-/datormodell, operativsystem, webbläsare, maskerad IP-adress och liknande samlas in.
                    Läs mer om hur Google hanterar datan i deras
                    <a href="https://policies.google.com/technologies/partner-sites">policy</a>. 
                    Du kan även stänga av insamling av din statistik med Googles 
                    <a href="https://tools.google.com/dlpage/gaoptout">webbläsar-plugin</a>.
                </p>
                <h2 class="panelTitle">Cookies</h2>
                <p>
                    För att den här hemsidan ska fungera korrekt används cookies och liknande teknik för att komma ihåg detaljer om ditt besök.
                </p>
                <p>
                    När du använder hemsidan placeras även tredjeparts-cookies på din dator av Google Analytics för att kunna analysera hur besökare använder hemsidan.
                </p>
                <p>
                    Du kan stänga av cookies i din webbläsares inställningar, men en del funktionalitet på sidan kan då sluta fungera.
                </p>
            </div>
        </div>
        <cookie-banner
            @click:cookiesReadMore="showCookiesInfo"/>
    </div>
</template>

<script>
import StopwatchList from "../components/StopwatchList.vue"
import CookieBanner from "../../common/components/CookieBanner.vue"
import { createNamespacedHelpers } from 'vuex'

const { mapState, mapGetters, mapMutations } = createNamespacedHelpers('stopwatch')

var loop = null;

export default {
    computed: {
        ...mapState([
            'infoOpen'
        ]),
        ...mapGetters([
            'stopwatches',
            'filtered',
            'sorted'
        ]),
        anyRunning: function() {
            return this.stopwatches.some(function(sw) {
                return sw.isResumed();
            })
        },
        sidePanelOpen: function() {
            return this.infoOpen;
        },
        sidePanelClass: function() {
            return this.sidePanelOpen ? "open" : "closed";
        },
        overlayClass: function() {
            return this.sidePanelOpen ? "visible" : "hidden";
        },
        buttonInfoClass: function() {
            return ["buttonInfo", this.infoOpen ? "selected" : null];
        },
        infoClass: function() {
            return !this.infoOpen ? "hidden" : null;
        }
    },
    watch: {
        'anyRunning': function() {
            this.loopIfAnyRunning();
        }
    },
    methods: {
        ...mapMutations([
            'toggleResumedPaused',
            'lap',
            'reset',
            'removeAll',
            'newStopwatch',
            'tick',
            'sortBy',
            'toggleInfo',
            'openInfo'
        ]),
        createNewStopwatch: function() {
            this.newStopwatch();
            this.$gtag.event('add', {
                'event_category': 'stopwatch'
            });
        },
        loopIfAnyRunning: function() {
            if (this.anyRunning && !this.looping()) {
                this.startLoop();
            } else if (!this.anyRunning && this.looping()) {
                this.stopLoop();
            }
        },
        looping: function() {
            return loop !== null
        },
        startLoop: function() {
            if (loop === null) {
                loop = setInterval(this.tickClock, 37);
            }
        },
        stopLoop: function() {
            clearInterval(loop)
            loop = null
        },
        tickClock: function() {
            this.tick();
        },
        showCookiesInfo: function() {
            this.openInfo();
            const infoPanel = this.$refs.info;
            infoPanel.scrollTop = infoPanel.scrollHeight;
        },
        keyListener: function(evt) {
            if (evt.target.tagName.toLowerCase() == "input") return;
            if (this.filtered.length == 1) { // Only one active stopwatch. Control it!
                const id = this.filtered[0].id;
                if (evt.keyCode == 32) { //space
                    this.toggleResumedPaused(id);
                    evt.preventDefault();
                } else if (evt.keyCode == 86) { // v
                    this.lap(id);
                } else if (evt.keyCode == 46) { // delete
                    this.reset(id);
                }
            }
        }
    },
    created: function() {
        document.addEventListener('keydown', this.keyListener);
    },
    mounted: function() {
        this.loopIfAnyRunning();
    },
    beforeDestroy: function() {
        document.removeEventListener('keydown', this.keyListener);
        this.stopLoop();
    },
    components: {
        StopwatchList,
        CookieBanner
    }
}
</script>

<style lang="scss" scoped>
@use "sass:map";

#topBox {
    width: 100%;
    height: 100vh;
    position: relative;
    @media screen and (min-width: sizes.$breakpointDesktop) {
        display: flex;
    }
}

#contentBox {
    width: 100%;
    @media screen and (min-width: sizes.$breakpointDesktop) {
        flex: 1 1 0%;
    }
    height: 100%;
    overflow: auto;
}

$sidePanelWidth: 360px;
$sidePanelWidthSmallPhone: 300px;
$sidePanelTransitionLength: 0.4s;
$logoHeights: ("phone": 42px, "tablet": 56px);

@mixin headerHeight {
    min-height: map.get($logoHeights, "phone");
    margin-top: 32px;
    margin-bottom: 24px;
    @media screen and (min-width: sizes.$breakpointTablet) {
        min-height: map.get($logoHeights, "tablet");
        margin-top: 56px;
        margin-bottom: 36px;
    }
}

#overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: colors.$primaryContent;
    transition: visibility $sidePanelTransitionLength, opacity $sidePanelTransitionLength;
    @media screen and (min-width: sizes.$breakpointDesktop) {
        display: none;
    }
    &.visible {
        opacity: 0.8;
        visibility: visible;
    }
    &.hidden {
        opacity: 0;
        visibility: hidden;
    }
}

#sidePanel {
    &.open {
        width: $sidePanelWidthSmallPhone;
        @media screen and (min-width: sizes.$breakpointLargePhone) {
            width: $sidePanelWidth;
        }
    }
    &.closed {
        width: 0%;
        overflow: hidden;
    }
    position: absolute;
    top: 0;
    right: 0;
    @media screen and (min-width: sizes.$breakpointDesktop) {
        position: relative;
        flex: 0 0 auto;
    }
    background: colors.$white;
    height: 100%;
    transition: width $sidePanelTransitionLength;
}

@mixin sidePanelContent {
    position: absolute;
    width: $sidePanelWidthSmallPhone;
    @media screen and (min-width: sizes.$breakpointLargePhone) {
        width: $sidePanelWidth;
    }
    height: 100%;
    overflow: auto;
    padding: 0px 36px 36px;
    opacity: 1;
    transition: visibility $sidePanelTransitionLength, opacity $sidePanelTransitionLength;
    &.hidden {
        visibility: hidden;
        opacity: 0;
    }
}

#info {
    @include sidePanelContent;
    p {
        @include mixins.text;
    }
}

.panelHeader {
    @include headerHeight;
    display: flex;
    align-items: center;

    :first-child {
        @include mixins.title;
        margin: 0;
        flex: 1 1 0%;
    }
}

.panelTitle {
    @include mixins.title;
    margin: 36px 0px 24px;
}

#stopwatchView {
    width: 100%;
    max-width: 840px;
    margin: 0px auto;
    padding: 0px 16px;

    @media screen and (min-width: sizes.$breakpointTablet) {
        margin: 0px auto;
        padding: 0px 36px;
    }
}

#header {
    @include headerHeight;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

#logo {
    height: map.get($logoHeights, "phone");
    margin-left: 13px;

    @media screen and (min-width: sizes.$breakpointTablet) {
        height: map.get($logoHeights, "tablet");
        margin-left: 33px;
    }
}

#controlButtons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
}

.buttonInfo {
    @include mixins.buttonIcon(
        $icon: url("../../../assets/images/buttoninfo.svg"),
        $iconHover: url("../../../assets/images/buttoninfoactive.svg"),
        $backgroundBaseColor: colors.$primary
    );
    margin-right: 16px;
    @media screen and (min-width: sizes.$breakpointTablet) {
        margin-right: 24px;
    }
}

.buttonRemoveAll {
    @include mixins.buttonIcon(
        $icon: url("../../../assets/images/buttonremove.svg"),
        $iconHover: url("../../../assets/images/buttonremovehover.svg"),
        $backgroundBaseColor: colors.$primaryContent
    );
    margin-right: 16px;
    @media screen and (min-width: sizes.$breakpointTablet) {
        margin-right: 24px;
    }
}

.buttonAdd {
    @include mixins.buttonRoundFilledWithIcon(
        $size: 42px,
        $icon: url("../../../assets/images/iconadd.svg")
    );
    margin-right: 13px;
    @media screen and (min-width: sizes.$breakpointTablet) {
        margin-right: 33px;
    }
}

.buttonCloseInfo {
    @include mixins.buttonIcon(
        $icon: url("../../../assets/images/buttonremove.svg"),
        $iconHover: url("../../../assets/images/buttonremovehover.svg"),
        $backgroundBaseColor: colors.$primaryContent
    );
}

.stopwatchList {
    clear: both;
}
</style>
<template>
    <div id="cookieBannerContainer" :class="visibilityClass">
        <div id="cookieBannerCenter">
            <div id="cookieBanner">
                <p>
                    Tidtagare.se använder cookies och liknande teknik för att förbättra tjänsten och hantera besöksstatistik.
                </p>
                <div class="buttons">
                    <button
                        id="buttonCookiesReadMore"
                        @click="readMore">
                        Läs mer
                    </button>
                    <button
                        id="buttonCookiesAccept"
                        @click="acceptCookies">
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            hasAcceptedCookies: this.$cookies.get("hasAcceptedCookies") === "true",
            hasDismissedBanner: false
        }
    },
    computed: {
        visible: function() {
            return !this.hasAcceptedCookies && !this.hasDismissedBanner;
        },
        visibilityClass: function() {
            return this.visible ? "visible" : "hidden";
        }
    },
    watch: {
        hasAcceptedCookies: function(accepted) {
            this.$cookies.set("hasAcceptedCookies", accepted ? "true" : "false", -1);
        }
    },
    methods: {
        acceptCookies: function() {
            this.hasAcceptedCookies = true;
        },
        readMore: function() {
            this.hasDismissedBanner = true;
            this.$emit("click:cookiesReadMore")
        }
    }
}
</script>

<style lang="scss" scoped>
@use "sass:color";

#cookieBannerContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    &.visible {
        opacity: 1;
        visibility: visible;
    }
    &.hidden {
        opacity: 0;
        visibility: hidden;
        transition: visibility 0.4s, opacity 0.4s;
    }
}

#cookieBannerCenter {
    max-width: 840px;
    margin: 0px auto;
}

#cookieBanner {
    margin: 0px 16px 16px;
    padding: 24px 36px;
    background: color.scale(colors.$darkBlueGray, $lightness: -20%);
    color: colors.$white;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: sizes.$breakpointTablet) {
        flex-direction: row;
        align-items: center;
        margin: 0px 36px 16px;
    }

    p {
        @include mixins.text;
        margin: 0 0 24px 0;
        text-align: center;

        @media screen and (min-width: sizes.$breakpointTablet) {
            margin: 0 24px 0 0;
            text-align: left;
        }
    }

    .buttons {
        flex: 1 0 auto;
        text-align: center;
    }
}

#buttonCookiesReadMore {
    @include mixins.buttonRegularOutlined(
        $color: colors.$white,
        $outlineColor: colors.$gray5
    ) {
        margin: 0px 12px 0px auto;
        min-width: 104px;
    }
}

#buttonCookiesAccept {
    @include mixins.buttonRegularFilled(
        $backgroundColor: colors.$white,
        $color: colors.$primary
    ) {
        margin: 0px auto 0px 12px;
        min-width: 104px;
    }
}
</style>
export default class Clock {
    constructor(data) {
        this.now = data.now;
        this.offset = data.offset;
        this.resumedAt = data.resumedAt;
    }
    totalAt(time) {
        var running = this.isResumed() ? time - this.resumedAt : 0;
        return this.offset + running;
    }
    total() {
        return this.totalAt(this.now);
    }
    isResumed() {
        return this.resumedAt !== null;
    }
    isStarted() {
        return this.isResumed() || this.offset !== null;
    }
}

<template>
    <div class="stopwatch">
        <input
            :value="localName"
            @input="editingName=$event.target.value"
            @change="renameThis($event.target.value)"
            @keyup.escape.enter="$event.target.blur()"
            :placeholder="stopwatch.placeholderName"
            class="stopwatchName"/>
        <button @click="removeThis" v-if="count > 1" class="buttonRemove"></button>
        <formatted-time
            :time="totalTime"
            class="totalTime" />
        <button
            @click="resumeOrPause"
            :class="[buttonResumePause.classes]">
            {{buttonResumePause.text}}
        </button>
        <button
            @click="lapOrReset"
            :class="[buttonLapReset.classes]"
            :disabled="buttonLapReset.disabled">
            {{buttonLapReset.text}}
        </button>
        <laps-container :stopwatch="stopwatch"/>
    </div>
</template>

<script>
import FormattedTime from "./FormattedTime.vue"
import LapsContainer from "./LapsContainer.vue"
import { createNamespacedHelpers } from 'vuex'
const { mapMutations } = createNamespacedHelpers('stopwatch')

export default {
    props: ['stopwatch', 'count'],
    data: function() {
        return {
            editingName: null
        }
    },
    computed: {
        localName: function() {
            return (this.editingName !== null) ? this.editingName : this.stopwatch.name;
        },
        totalTime: function() {
            return this.stopwatch.total();
        },
        buttonResumePause: function() {
            const isButtonStop = this.stopwatch.isResumed();
            return {
                text: isButtonStop ? "Stopp" : (this.stopwatch.isStarted() ? "Fortsätt" : "Start"),
                classes: [isButtonStop ? "pauseButton" : "resumeButton", "buttonResumePause"]
            }
        },
        buttonLapReset: function() {
            const isButtonLap = !this.stopwatch.isStarted() || this.stopwatch.isResumed();
            return {
                text: isButtonLap ? "Varv" : "Nollställ",
                classes: [isButtonLap ? "lapButton" : "resetButton", "buttonLapReset"],
                disabled: !this.stopwatch.isStarted()
            }
        }
    },
    methods: {
        ...mapMutations([
            'resume',
            'pause',
            'lap',
            'reset',
            'rename',
            'remove'
        ]),
        resumeOrPause: function() {
            if (this.stopwatch.isResumed()) {
                this.pause(this.stopwatch.id);
                this.$gtag.event('stop', {
                    'event_category': 'stopwatch',
                    'value': parseInt(this.stopwatch.total() / 1000)
                })
            } else {
                const started = this.stopwatch.isStarted();
                this.resume(this.stopwatch.id);
                this.$gtag.event(!started ? 'start' : 'continue', {
                    'event_category': 'stopwatch'
                })
            }
        },
        lapOrReset: function() {
            if (this.stopwatch.isResumed()) {
                const lapTime = this.stopwatch.currentLap().lapTime();
                this.lap(this.stopwatch.id);
                this.$gtag.event('lap', {
                    'event_category': 'stopwatch',
                    'value': parseInt(lapTime / 1000)
                })
            } else if (this.stopwatch.isStarted()) {
                this.reset(this.stopwatch.id);
            }
        },
        removeThis: function() {
            this.remove(this.stopwatch.id);
            this.$gtag.event('remove', {
                'event_category': 'stopwatch'
            })
        },
        renameThis: function(newName) {
            this.editingName = null;
            this.rename({ id: this.stopwatch.id, newName: newName });
        }
    },
    components: {
        FormattedTime,
        LapsContainer
    }
}
</script>

<style lang="scss" scoped>
.stopwatch {
    background-color: #fff;
    padding: 16px 16px 36px;
    @media screen and (min-width: sizes.$breakpointTablet) {
        padding: 30px 36px 54px;
    }
    border-radius: 24px;
    text-align: center;
    position: relative;
}

.stopwatchName {
    width: calc(100% - 112px);
    border: none;
    outline: none;
    padding: 6px 16px;
    border-radius: 100px;
    font-family: LatoSemibold;
    font-size: 18px;
    text-align: center;
    color: colors.$primaryContent;
    @media screen and (min-width: sizes.$breakpointTablet) {
        font-size: 20px;
    }

    &:focus {
        background-color: colors.$background;
    }
    &::placeholder {
        color: colors.$secondaryContent;
        opacity: 1;
    }
}

.totalTime {
    font-size: 48px;
    font-family: 'LatoSemibold';
    margin-top: 24px;
    margin-bottom: 24px;
    @media screen and (min-width: sizes.$breakpointLargePhone) {
        font-size: 56px;
    }
    @media screen and (min-width: sizes.$breakpointTablet) {
        font-size: 88px;
    }

    ::v-deep .unit {
        font-size: 24px;
        font-family: 'LatoThin';
        margin-right: 8px;
        @media screen and (min-width: sizes.$breakpointLargePhone) {
            font-size: 28px;
        }
        @media screen and (min-width: sizes.$breakpointTablet) {
            font-size: 36px;
            margin-right: 16px;
        }
    }
    ::v-deep .millis {
        font-size: 28px;
        font-family: 'Lato';
        @media screen and (min-width: sizes.$breakpointLargePhone) {
            font-size: 32px;
        }
        @media screen and (min-width: sizes.$breakpointTablet) {
            font-size: 44px;
        }
    }
}

$marginButtonPhone: 8px;
$marginButtonLarger: 16px;

@mixin stopwatchButton($background) {
    @include mixins.buttonRegularFilled($height: 42px, $backgroundColor: $background) {
        width: calc(50% - #{$marginButtonPhone});
        padding: 0px;
        @media screen and (min-width: sizes.$breakpointTablet) {
            width: 160px;
            padding: 0px;
        }
    }
}

.resumeButton {
    @include stopwatchButton(colors.$green);
}

.pauseButton {
    @include stopwatchButton(colors.$red);
}

.buttonResumePause {
    margin-right: $marginButtonPhone;
    @media screen and (min-width: sizes.$breakpointTablet) {
        margin-right: $marginButtonLarger;
    }
}

.lapButton {
    @include stopwatchButton(colors.$blue);
}

.resetButton {
    @include stopwatchButton(colors.$darkBlueGray);
}

.buttonLapReset {
    margin-left: $marginButtonPhone;
    @media screen and (min-width: sizes.$breakpointTablet) {
        margin-left: $marginButtonLarger;
    }
}

.buttonRemove {
    @include mixins.buttonIcon(
        $icon: url("../../../assets/images/buttonremove.svg"),
        $iconHover: url("../../../assets/images/buttonremovehover.svg"),
        $backgroundBaseColor: colors.$primaryContent
    ) {
        position: absolute;
        top: 16px;
        right: 16px;
        @media screen and (min-width: sizes.$breakpointTablet) {
            top: 30px;
            right: 36px;
        }
    }
}
</style>
<template>
    <tr :class="['lapRow', isResumed ? 'resumed' : '']">
        <td>
            <input
                :value="localName"
                @input="editingName=$event.target.value"
                @change="rename($event.target.value)"
                @keyup.escape.enter="$event.target.blur"
                :placeholder="lap.placeholderName"
                class="lapName"/>
        </td>
        <td>
            <formatted-time
                class="lapTime formattedTime"
                :time="lapTime"/>
        </td>
        <td>
            <formatted-time
                class="splitTime formattedTime"
                :time="splitTime"/>
        </td>
    </tr>
</template>

<script>
import FormattedTime from "./FormattedTime.vue"

export default {
    props: ['lap'],
    data: function() {
        return {
            editingName: null
        }
    },
    computed: {
        localName: function() {
            return (this.editingName !== null) ? this.editingName : this.lap.name;
        },
        lapTime: function() {
            return this.lap.lapTime();
        },
        splitTime: function() {
            return this.lap.splitTime();
        },
        isResumed: function() {
            return this.lap.isResumed();
        }
    },
    methods: {
        rename: function(newName) {
            this.editingName = null;
            this.$emit('rename', this.lap.id, newName);
        }
    },
    components: {
        FormattedTime
    }
}
</script>

<style lang="scss" scoped>
.lapName {
    border: none;
    outline: none;
    width: 100%;
    padding: 6px 0px;
    font-family: 'Lato';
    font-size: 15px;
    color: colors.$primaryContent;
    @media screen and (min-width: sizes.$breakpointTablet) {
        font-size: 16px;
    }

    &::placeholder {
        color: colors.$primaryContent;
        opacity: 1;
        font-family: 'Lato';
    }
    &:focus::placeholder {
        color: colors.$secondaryContent;
    }
}

.formattedTime {
    font-size: 17px;
    font-family: 'LatoSemibold';
    margin-left: 8px;
    @media screen and (min-width: sizes.$breakpointTablet) {
        font-size: 18px;
    }

    ::v-deep .unit {
        font-size: 13px;
        margin-right: 4px;
        @media screen and (min-width: sizes.$breakpointTablet) {
            font-size: 14px;
            margin-right: 6px;
        }
    }
    ::v-deep .millis {
        font-size: 13px;
        @media screen and (min-width: sizes.$breakpointTablet) {
            font-size: 14px;
        }
    }
}

.lapRow.resumed .formattedTime {
    font-family: 'LatoLight';
}
</style>
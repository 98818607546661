import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueGtag from 'vue-gtag'
import VueCookies from 'vue-cookies'

Vue.config.productionTip = false

import "./assets/fonts/fonts.css"

Vue.use(VueGtag, {
  config: {
    id: "G-ZP097CY9R6",
    params: {
      anonymize_ip: true
    }
  }
}, router);

Vue.use(VueCookies)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import Stopwatch from '../model/stopwatch.js'

function getById(state, id) {
    return state.stopwatches.find(s => s.id === id);
}

function totalAt(clock, time) {
    var running = isResumed(clock) ? time - clock.resumedAt : 0;
    return clock.offset + running;
}

function isResumed(clock) {
    return clock.resumedAt !== null;
}

function currentLap(stopwatch) {
    return stopwatch.laps[stopwatch.laps.length - 1];
}

function resumeClock(clock, resumeAt) {
    clock.resumedAt = resumeAt;
    clock.now = resumeAt;
}

function pauseClock(clock, pauseAt) {
    clock.offset += pauseAt - clock.resumedAt;
    clock.resumedAt = null;
    clock.now = null;
}

function lap(stopwatch, lapTimestamp) {
    if (isResumed(stopwatch.clock)) {
        var split = totalAt(stopwatch.clock, lapTimestamp);
        pauseClock(currentLap(stopwatch).clock, lapTimestamp);
        stopwatch.laps.push(newLap(stopwatch.laps.length, split));
        resumeClock(currentLap(stopwatch).clock, lapTimestamp);
    }
}

var swSortFunctions = {
    id: {
        name: "id",
        compare: function(a, b) { return a.id - b.id; },
        order: -1
    },
    name: {
        name: "name",
        compare: function(a, b) {
            return a.nameOrPlaceholder().localeCompare(b.nameOrPlaceholder());
        },
        order: 1
    },
    time: {
        name: "time",
        compare: function(a, b) { return a.total() - b.total(); },
        order: 1
    }
};

function newClock() {
    return {
        now: null,
        offset: null,
        resumedAt: null,
    }
}

function newLap(id, startOffset) {
    return {
        id: id,
        clock: newClock(),
        startOffset: startOffset,
        name: "",
    }
}

function newStopwatch(id) {
    return {
        id: id,
        clock: newClock(),
        laps: [newLap(0, 0)],
        name: "",
    }
}

export default {
    namespaced: true,
    state: {
        stopwatches: [newStopwatch(0)],
        nextId: 1,
        sorts: swSortFunctions,
        sortedBy: swSortFunctions.id,
        searchQuery: "",
        infoOpen: false
    },
    getters: {
        stopwatches: (state) => {
            return state.stopwatches.map(item => new Stopwatch(item));
        },
        filtered: (state, getters) => {
            /*var vm = this;
            return getters.stopwatches.filter(function(sw) {
                return sw.nameOrPlaceholder().toLowerCase().indexOf(vm.searchQuery.toLowerCase()) !== -1;
            });*/
            return getters.stopwatches;
        },
        sorted: (state, getters) => {
            /*return getters.filtered.slice().sort(function(a, b) {
                return state.sortedBy.compare(a, b) * state.sortedBy.order;
            });*/
            return getters.filtered.slice().reverse();
        },
    },
    mutations: {
        resume (state, id) {
            var stopwatch = getById(state, id);
            if (stopwatch != undefined && !isResumed(stopwatch.clock)) {
                var now = Date.now();
                resumeClock(stopwatch.clock, now);
                resumeClock(currentLap(stopwatch).clock, now);
            }
        },
        pause (state, id) {
            var stopwatch = getById(state, id);
            if (stopwatch != undefined && isResumed(stopwatch.clock)) {
                var now = Date.now();
                pauseClock(stopwatch.clock, now)
                pauseClock(currentLap(stopwatch).clock, now);
            }
        },
        toggleResumedPaused (state, id) {
            var stopwatch = getById(state, id);
            if (stopwatch != undefined) {
                var now = Date.now();
                if (isResumed(stopwatch.clock)) {
                    pauseClock(stopwatch.clock, now)
                    pauseClock(currentLap(stopwatch).clock, now);
                } else {
                    resumeClock(stopwatch.clock, now);
                    resumeClock(currentLap(stopwatch).clock, now);
                }
            }
        },
        lap (state, id) {
            var stopwatch = getById(state, id);
            if (stopwatch != undefined && isResumed(stopwatch)) {
                lap(stopwatch, Date.now());
            }
        },
        reset (state, id) {
            var stopwatch = getById(state, id);
            if (stopwatch != undefined) {
                stopwatch.clock = newClock();
                stopwatch.laps = [newLap(0, 0)];
            }
        },
        remove (state, id) {
            var index = state.stopwatches.findIndex(s => s.id === id);
            if (index != -1) {
                state.stopwatches.splice(index, 1);
            }
        },
        rename (state, { id, newName } ) {
            getById(state, id).name = newName;
        },
        renameLap (state, { stopwatchId, lapId, newName }) {
            getById(state, stopwatchId).laps[lapId].name = newName;
        },
        resumeAll (state) {
            var now = Date.now();
            state.stopwatches.forEach(function(stopwatch) {
                if (!isResumed(stopwatch.clock)) {
                    resumeClock(stopwatch.clock, now);
                    resumeClock(currentLap(stopwatch).clock, now);
                }
            })
        },
        pauseAll (state) {
            var now = Date.now();
            state.stopwatches.forEach(function(stopwatch) {
                if (isResumed(stopwatch.clock)) {
                    pauseClock(stopwatch.clock, now);
                    pauseClock(currentLap(stopwatch).clock, now);
                }
            })
        },
        newStopwatch (state) {
            state.stopwatches.push(newStopwatch(state.nextId++));
        },
        removeAll (state) {
            state.stopwatches = [newStopwatch(0)];
            state.nextId = 1;
        },
        tick (state) {
            var now = Date.now();
            state.stopwatches.forEach(function(stopwatch) {
                if (isResumed(stopwatch.clock)) {
                    stopwatch.clock.now = now;
                    stopwatch.laps.forEach(lap => lap.clock.now = now )
                }
            })
        },
        sortBy (state, sortBy) {
            sortBy.order *= state.sortedBy === sortBy ? -1 : 1; // Reverse order if already using this sort function
            state.sortedBy = sortBy;
        },

        toggleInfo (state) {
            state.infoOpen = !state.infoOpen;
        },
        openInfo (state) {
            state.infoOpen = true;
        }
    },
    actions: {}
}
<template>
    <div>
        <stopwatch-list-item
            v-for="stopwatch in stopwatchList"
            :key="stopwatch.id"
            :stopwatch="stopwatch"
            :count="stopwatchCount"
            class="stopwatchListItem">
        </stopwatch-list-item>
    </div>
</template>

<script>
import StopwatchListItem from "./StopwatchListItem.vue"

export default {
    props: ['stopwatchList'],
    computed: {
        stopwatchCount: function() {
            return this.stopwatchList.length
        }
    },
    components: {
        StopwatchListItem
    }
}
</script>

<style lang="scss" scoped>
.stopwatchListItem {
    margin-bottom: 48px;
    @media screen and (min-width: sizes.$breakpointTablet) {
        margin-bottom: 64px;
    }
}
</style>
import Clock from "./clock.js"

export default class Lap {
    constructor(data) {
        this.id = data.id;
        this.clock = new Clock(data.clock);
        this.startOffset = data.startOffset;
        this.name = data.name;
        this.placeholderName = "Varv " + (this.id + 1);
    }
    nameOrPlaceholder() {
        return this.name.length > 0 ? this.name : this.placeholderName;
    }
    lapTime() {
        return this.clock.total();
    }
    splitTime() {
        return this.lapTime() + this.startOffset;
    }
    isResumed() {
        return this.clock.isResumed();
    }
    isStarted() {
        return this.clock.isStarted();
    }
}

import Vue from 'vue'
import 'es6-promise/auto'
import Vuex from 'vuex'
import stopwatch from '../modules/stopwatch/store'
import sessionPersist from './session_persist.js'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        stopwatch
    },
    plugins: [sessionPersist.plugin]
})
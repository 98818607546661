<template>
    <div class="lapsContainer" v-if="showLaps">
        <table class="lapsTable">
            <colgroup>
                <col class="lapNameColumn">
                <col class="lapTimeColumn">
                <col class="splitTimeColumn">
            </colgroup>
            <thead>
                <tr>
                    <th>
                        <span class="columnTitle" @click="sortBy(sorts.name)">Varv</span>
                        <img
                            @click="sortBy(sorts.name)"
                            :class="['iconSortOrder', sortedBy !== sorts.name ? 'hidden' : '']"
                            :src="iconSortOrder"/>
                    </th>
                    <th>
                        <img
                            @click="sortBy(sorts.lapTime)"
                            :class="['iconSortOrder', sortedBy !== sorts.lapTime ? 'hidden' : '']"
                            :src="iconSortOrder"/>
                        <span class="columnTitle" @click="sortBy(sorts.lapTime)">Varvtid</span>
                    </th>
                    <th>
                        <img
                            @click="sortBy(sorts.id)"
                            :class="['iconSortOrder', sortedBy !== sorts.id ? 'hidden' : '']"
                            :src="iconSortOrder"/>
                        <span class="columnTitle" @click="sortBy(sorts.id)">Mellantid</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <lap-item
                    v-for="lap in sortedLaps"
                    :key="lap.id"
                    :lap="lap"
                    @rename="rename"/>
            </tbody>
        </table>
        <div class="lapSummary">
            <div>Snabbast<br/><formatted-time class="formattedTime fastestTime" :time="fastestTime" /></div>
            <div class="middle">Snitt<br/><formatted-time class="formattedTime averageTime" :time="averageTime" /></div>
            <div>Långsammast<br/><formatted-time class="formattedTime slowestTime" :time="slowestTime" /></div>
        </div>
    </div>
</template>

<script>
import FormattedTime from "./FormattedTime.vue"
import LapItem from "./LapItem.vue"
import { createNamespacedHelpers } from 'vuex'
const { mapMutations } = createNamespacedHelpers('stopwatch')

var lapSortFunctions = {
    id: {
        name: "id",
        compare: function(a, b) { return a.id - b.id; },
        order: -1
    },
    lapTime: {
        name: "lap time",
        compare: function(a, b) { return a.lapTime() - b.lapTime(); },
        order: 1
    },
    name: {
        name: "name",
        compare: function(a, b) {
            return a.nameOrPlaceholder().localeCompare(b.nameOrPlaceholder());
        },
        order: 1
    }
};

export default {
    props: ['stopwatch'],
    data: function() {
        return {
            sorts: lapSortFunctions,
            sortedBy: lapSortFunctions.id
        }
    },
    computed: {
        sortedLaps: function() {
            var c = this;
            return this.stopwatch.laps.slice().sort(function(a, b) {
                return c.sortedBy.compare(a, b) * c.sortedBy.order;
            });
        },
        showLaps: function() {
            return this.stopwatch.laps.length > 1;
        },
        fastestLap: function() {
            var fastest = null;
            for (const lap of this.stopwatch.laps) {
                if (!lap.isResumed() && (fastest == null || lap.lapTime() < fastest.lapTime())) {
                    fastest = lap;
                }
            }
            return fastest;
        },
        fastestTime: function() {
            return this.fastestLap.lapTime();
        },
        slowestLap: function() {
            var slowest = null;
            for (const lap of this.stopwatch.laps) {
                if (!lap.isResumed() && (slowest == null || lap.lapTime() > slowest.lapTime())) {
                    slowest = lap;
                }
            }
            return slowest;
        },
        slowestTime: function() {
            return this.slowestLap.lapTime();
        },
        averageTime: function() {
            var currentLap = this.stopwatch.currentLap();
            var running = currentLap.isResumed();
            var total = running ? currentLap.startOffset : currentLap.splitTime();
            var laps = this.stopwatch.laps.length - (running ? 1 : 0);
            return parseInt(total / laps);
        },
        iconSortOrder: function() {
            const icon = this.sortedBy.order > 0 ? "sortasc.svg" : "sortdesc.svg";
            return require("../../../assets/images/" + icon);
        }
    },
    methods: {
        ...mapMutations([
            'renameLap'
        ]),
        sortBy: function(sortBy) {
            sortBy.order *= this.sortedBy === sortBy ? -1 : 1; // Reverse order if already using this sort function
            this.sortedBy = sortBy;
        },
        rename: function(lapId, newName) {
            this.renameLap({ stopwatchId: this.stopwatch.id, lapId: lapId, newName: newName });
        }
    },
    components: {
        LapItem,
        FormattedTime
    }
}
</script>

<style lang="scss" scoped>
.lapsContainer {
    margin-top: 32px;
    @media screen and (min-width: sizes.$breakpointTablet) {
        margin-top: 40px;
    }
}

.lapsTable {
    width: 100%;
    white-space: nowrap;
    border-spacing: 0px;
    border-collapse: collapse;

    ::v-deep tr {
        height: 42px;
    }
    th {
        font-family: 'LatoThin';
        font-size: 16px;
        text-align: right;
        padding-left: 0px;
        @media screen and (min-width: sizes.$breakpointLargePhone) {
            padding-left: 8px;
        }
        @media screen and (min-width: sizes.$breakpointTablet) {
            font-size: 18px;
            padding-left: 32px;
        }
        @media screen and (min-width: sizes.$breakpointDesktop) {
            padding-left: 48px;
        }

        &:first-child {
            text-align: left;
            padding-left: 0px;
        }
    }
    .columnTitle {
        cursor: pointer;
    }

    ::v-deep td {
        text-align: right;

        &:first-child {
            text-align: left;
        }
    }

    .lapNameColumn {
        width: 100%;
    }

    .iconSortOrder {
        vertical-align: middle;
        cursor: pointer;
        &.hidden {
            visibility: hidden;
        }
    }
}

.lapSummary {
    margin-top: 32px;

    > div {
        display: inline-block;
        width: 30%;
        overflow-wrap: anywhere;
        font-family: 'LatoLight';
        font-size: 14px;
        @media screen and (min-width: sizes.$breakpointTablet) {
            width: auto;
            font-size: 15px;
        }
        &.middle {
            margin: 0 5%;
            @media screen and (min-width: sizes.$breakpointTablet) {
                margin: 0px 56px;
            }
        }
    }

    .formattedTime {
        font-size: 17px;
        font-family: 'LatoSemibold';
        margin-top: 4px;
        @media screen and (min-width: sizes.$breakpointTablet) {
            font-size: 20px;
        }

        ::v-deep .unit {
            font-size: 14px;
            font-family: 'Lato';
            margin-right: 4px;
            @media screen and (min-width: sizes.$breakpointTablet) {
                font-size: 17px;
                margin-right: 5px;
            }
        }

        ::v-deep .millis {
            font-size: 14px;
            font-family: 'Lato';
            @media screen and (min-width: sizes.$breakpointTablet) {
                font-size: 17px;
            }
        }
    }

    .slowestTime {
        color: colors.$red;
    }

    .averageTime {
        color: colors.$primary;
    }

    .fastestTime {
        color: colors.$green;
    }
}

</style>